<script setup lang="ts">
import { get } from 'lodash'
const { $e } = useNuxtApp()
const log = createLog('app:qrhunt:cancelDialog')

const { $headerBackTo, $qrHuntRouter } = useNuxtApp()
const qrHuntStore = useQRHuntStore()
$headerBackTo(ROUTE_TARGET.QRHUNT_BACK)

const isBuisy = inject('page-loading', ref(false))

// unref
const routeUid = toValue(get(qrHuntStore, 'getRunningRoute.uid'))

onWSMessage<SessionChangeResponse>(SessionChangeResponseSchema, (data) => {
    isBuisy.value = false
    $qrHuntRouter.push('overview')
})

onWSMessage<WsError>(ErrorSchema, (data) => {
    const errorMessage = $e(data.content.errorType)
    log.error(errorMessage)
    isBuisy.value = false
})
</script>

<template>
    <div class="page-cancel-dialog">
        <Icon
            name="stop-48x"
            fluid
            circle="#E59EB2"
            class="page-cancel-dialog__icon"
        />

        <RichText
            class="page-cancel-dialog__text rich-text--compact"
            :text="$t('page.routeCancelDialog.introText')"
        />

        <ButtonGroup class="page-cancel-dialog__buttons">
            <!-- <AppButton
                fluid
                :disabled="isBuisy"
                @click="
                    () => {
                        qrHuntStore.actionPauseRoute(routeUid)
                    }
                "
            >
                {{ $t('page.routeCancelDialog.buttons.confirmPause') }}
            </AppButton> -->

            <AppButton
                fluid
                variant="outlined"
                :disabled="isBuisy"
                @click="
                    () => {
                        isBuisy = true
                        qrHuntStore.actionAbortRoute(routeUid)
                    }
                "
            >
                {{ $t('page.routeCancelDialog.buttons.confirmCancel') }}
            </AppButton>

            <AppButton
                fluid
                variant="ghost"
                :disabled="isBuisy"
                @click="$qrHuntRouter.push('detail')"
            >
                {{ $t('page.routeCancelDialog.buttons.cancel') }}
            </AppButton>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-cancel-dialog {
    height: 100%;
    display: grid;
    grid-template-rows: auto 30px auto minmax(48px, 1fr) auto;
    grid-template-areas:
        'icon   '
        '.      '
        'text   '
        '.      '
        'buttons';

    &__icon {
        grid-area: icon;
        width: min(100%, 80px);
    }

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
